export const SCALE_PLAN = "SCALE";
export const GROWTH_PLAN = "GROWTH";
export const FREE_PLAN = "FREE";
export const VIP_PLAN = "VIP";

export const PLAN_FREE_ID = "FREE";
export const PLAN_GROWTH_ID = "GROWTH";
export const PLAN_SCALE_ID = "SCALE";
export const PLAN_VIP_ID = "VIP";

export const PLAN_DESCRIPTION_ID = 0;

export const MONTHLY_PLAN_IDS = [2, 3, 4, 10, 9, 8];
export const OLD_MONTHLY_PLAN_IDS = [2, 3, 4];
export const NEW_MONTHLY_PLAN_IDS = [10, 9, 8];

export const FREE_PLAN_ID = 1;
export const GROWTH_PLAN_IDS = [10, 5, 2];
export const SCALE_PLAN_IDS = [9, 6, 3];
export const VIP_PLAN_IDS = [8, 7, 4];

export const GROWTH_MONTHLY_PLAN_ID = 10;
export const GROWTH_ANNUAL_PLAN_ID = 5;

export const SCALE_MONTHLY_PLAN_ID = 9;
export const SCALE_ANNUAL_PLAN_ID = 6;

export const VIP_MONTHLY_PLAN_ID = 8;
export const VIP_ANNUAL_PLAN_ID = 7;

export const ANNUAL_PLAN_IDS = [5, 6, 7];

// store plan limitsfor each plan
export const PLAN_LIMITS = {
  FREE: {
    unbranded: 10,
    custom: 0,
    vip: 0,
  },
  GROWTH: {
    unbranded: null,
    custom: 10,
    vip: 0,
  },
  SCALE: {
    unbranded: null,
    custom: null,
    vip: 0,
  },
  VIP: {
    unbranded: null,
    custom: null,
    vip: null,
  },
};

export const MOBILE_PLAN_ORDER = [
  {
    plan_type: FREE_PLAN,
    number_of_features_to_display: 2,
  },
  {
    plan_type: GROWTH_PLAN,
    number_of_features_to_display: 7,
  },
  {
    plan_type: SCALE_PLAN,
    number_of_features_to_display: 7,
  },
  {
    plan_type: VIP_PLAN,
    number_of_features_to_display: 13,
  },
];

export const ERROR_PLAN_EXCEEDED = "ERROR_PLAN_EXCEEDED";
// export const ERROR_GROWWTH_PLAN_EXCEEDED = 'ERROR_GROWWTH_PLAN_EXCEEDED';

export const PAIRED_ANNUAL_AND_MONTHLY_PLANS = [{ 10: 5 }, { 9: 6 }, { 8: 7 }];

export const PAIRED_PLANS_ANNUAL_AND_MONTHLY = [
  { monthly: 10, annual: 5 },
  { monthly: 9, annual: 6 },
  { monthly: 8, annual: 7 },
];

export const ANNUAL_PLAN_DISCOUNT = {
  5: "Save 33%",
  6: "Save 45%",
  7: "Save 50%",
};

export const PLAN_PRICING_TABLE_INFO = {
  0: {
    id: 0,
    price: 0,
    plan_type: null,
    hasIcon: false,
    name: null,
    plan_description: null,
    features_list: [
      {
        feature: "Free product mock-ups",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Unbranded products",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Branded products*",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "One-time Brand Plate setup**",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Premium products",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Free product warehousing",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Blanka Formulator",
        hasIcon: false,
        isBold: false,
      },
      {
        feature: "Dedicated account manager",
        hasIcon: true,
        isBold: false,
      },
      {
        feature: "Dedicated phone support",
        hasIcon: true,
        isBold: false,
      },
      {
        feature: "Branded package inserts",
        hasIcon: true,
        isBold: false,
      },
      {
        feature: "Priority processing customer orders",
        hasIcon: true,
        isBold: true,
      },
      {
        feature: "Early access to new products/formulas",
        hasIcon: true,
        isBold: true,
      },
      {
        feature: "API Access",
        hasIcon: true,
        isBold: true,
      },
    ],
  },
  1: {
    id: 1,
    price: 0,
    plan_type: "FREE",
    name: "Free Plan - Monthly",
    hasIcon: false,
    plan_description: "Unbranded products to get you started.",
    features_list: [
      {
        feature: "Cross",
      },
      {
        feature: "10",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  2: {
    id: 2,
    price: 29,
    plan_type: "GROWTH",
    compare_to_price: 39,
    name: "Growth Plan - Monthly",
    hasIcon: false,
    plan_description: "Start your branded product line with room to grow.",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "10",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  3: {
    id: 3,
    price: 59,
    plan_type: "SCALE",
    compare_to_price: 79,
    name: "Scale Plan - Monthly",
    hasIcon: false,
    plan_description: "Expand your branded product line without limits!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  4: {
    id: 4,
    price: 99,
    plan_type: "VIP",
    compare_to_price: 149,
    name: "Vip Plan - Monthly",
    hasIcon: true,
    plan_description: "The tools to take your brand to the next level!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
        isBold: true,
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Upon request",
      },
    ],
  },
  5: {
    id: 5,
    price: 312,
    compare_to_price: 39,
    plan_type: "GROWTH",
    hasIcon: false,
    name: "Growth Plan - Annual",
    plan_description: "Start your branded product line with room to grow.",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "10",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  6: {
    id: 6,
    price: 588,
    compare_to_price: 79,
    plan_type: "SCALE",
    hasIcon: false,
    name: "Scale Plan - Annual",
    plan_description: "Expand your branded product line without limits!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  7: {
    id: 7,
    price: 888,
    compare_to_price: 149,
    plan_type: "VIP",
    hasIcon: true,
    name: "Vip Plan - Annual",
    plan_description: "The tools to take your brand to the next level!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
        isBold: true,
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Upon request",
      },
    ],
  },
  10: {
    id: 10,
    price: 39,
    plan_type: "GROWTH",
    compare_to_price: 29,
    name: "Growth Plan - Monthly",
    hasIcon: false,
    plan_description: "Start your branded product line with room to grow.",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "10",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  9: {
    id: 9,
    price: 79,
    compare_to_price: 59,
    plan_type: "SCALE",
    name: "Scale Plan - Monthly",
    hasIcon: false,
    plan_description: "Expand your branded product line without limits!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
      {
        feature: "Cross",
      },
    ],
  },
  8: {
    id: 8,
    price: 149,
    compare_to_price: 99,
    plan_type: "VIP",
    name: "Vip Plan - Monthly",
    hasIcon: true,
    plan_description: "The tools to take your brand to the next level!",
    features_list: [
      {
        feature: "Check",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Unlimited",
      },
      {
        feature: "Check",
        isBold: true,
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Check",
      },
      {
        feature: "Upon request",
      },
    ],
  },
};

export const PLAN_INFO = {
  0: {
    id: 0,
    price: 0,
    plan_type: null,
    features_list: [
      { feature: "Free product mock-ups" },
      { feature: "Unbranded products" },
      { feature: "Branded products" },
      { feature: "Premium products" },
      { feature: "Free product warehousing" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded package inserts" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
      { feature: "Priority processing for customer orders" },
      { feature: "Early access to new products and product formulas" },
      { feature: "Blanka Labs - Access to custom formulation" },
    ],
  },
  1: {
    id: 1,
    price: 0,
    plan_type: "FREE",
    name: "Free Plan - Monthly",
    features_list: [{ feature: "Create 10 unbranded products" }],
  },
  2: {
    id: 2,
    price: 29,
    plan_type: "GROWTH",
    name: "Growth Plan - Monthly",
    features_list: [
      { feature: "Create 10 branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  3: {
    id: 3,
    price: 59,
    plan_type: "SCALE",
    name: "Scale Plan - Monthly",
    features_list: [
      { feature: "Create unlimited branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  4: {
    id: 4,
    price: 99,
    plan_type: "VIP",
    name: "VIP Plan - Monthly",
    features_list: [
      { feature: "Everything in Scale Plan + MORE" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded inserts in orders" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
      { feature: "Priority Processing for Customer Orders" },
      { feature: "Early Access to New Products and Product Formulas" },
      { feature: "Blanka Labs - Access to custom formulation" },
    ],
  },
  5: {
    id: 5,
    price: 312,
    annual_savings: 36,
    plan_type: "GROWTH",
    name: "Growth Plan - Annual",
    features_list: [
      { feature: "Create 10 branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  6: {
    id: 6,
    price: 588,
    annual_savings: 120,
    plan_type: "SCALE",
    name: "Scale Plan - Annual",
    features_list: [
      { feature: "Create unlimited branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  7: {
    id: 7,
    price: 888,
    annual_savings: 300,
    plan_type: "VIP",
    name: "Vip Plan - Annual",
    features_list: [
      { feature: "Everything in Scale Plan + MORE" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded inserts in orders" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
      { feature: "Priority Processing for Customer Orders" },
      { feature: "Early Access to New Products and Product Formulas" },
      { feature: "Blanka Labs - Access to custom formulation" },
    ],
  },
  10: {
    id: 5,
    price: 39,
    annual_savings: 36,
    plan_type: "GROWTH",
    name: "Growth Plan - Monthly",
    features_list: [
      { feature: "Create 10 branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  9: {
    id: 6,
    price: 79,
    annual_savings: 120,
    plan_type: "SCALE",
    name: "Scale Plan - Monthly",
    features_list: [
      { feature: "Create unlimited branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
    ],
  },
  8: {
    id: 7,
    price: 149,
    annual_savings: 300,
    plan_type: "VIP",
    name: "Vip Plan - Monthly",
    features_list: [
      { feature: "Everything in Scale Plan + MORE" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded inserts in orders" },
      { feature: "Access to Premium products" },
      { feature: "One-time Brand plate/Brand Setup Fee: Included" },
      { feature: "Priority Processing for Customer Orders" },
      { feature: "Early Access to New Products and Product Formulas" },
      { feature: "Blanka Labs - Access to custom formulation" },
    ],
  },
};
