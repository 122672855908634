import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function usePageViews(user) {
  const location = useLocation();

  useEffect(() => {
    // set as user
    try {
      // return if not in production
      if (process.env.NODE_ENV !== "production") {
        return;
      }

      if (user !== null && user.shop) {
        window.analytics.identify(user?.id, {
          email: user?.email,
          name: user?.name,
          plan_id: user?.shop[0].plan,
          plan_name: user?.shop[0].plan_name,
          trial_days_remaining: user?.shop[0].trial_days_remaining,
          onboarding_step: user?.shop[0].onboarding_step,
          brand_plate_created: user?.shop[0].brand_plate_created,
          subscription_status: user?.shop[0].subscription_status,
          custom_product_status: user?.shop[0].custom_product_status,
          website: user?.shop[0].url,
        });

        window.FS.identify(user?.email, {
          displayName: user?.name,
          email: user?.email,
        });

        window.gtag("set", {
          user_id: `${user?.id}`,
        });

        window.amplitude.setUserId(user?.id);
      }
    } catch (err) {
      console.error(err);
    }

    // catch gtag for testing purposes
    try {
      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_location: location.pathname,
      });

      window.analytics.page(location.name, {
        title: location.name,
        path: location.pathname,
        url: window.location.href,
      });
    } catch (err) {
      // console.log("Error loading gtag");
    }
  }, [location]);
}
