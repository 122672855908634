import PropTypes from "prop-types";
import React from "react";

// mui components:
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardContent, Divider, Stack } from "@mui/material";
import ComingSoonTag from "components/Labels/ComingSoonTag";
// custom components & constants:
import Button from "components/Buttons/Button";
import { CrownIcon } from "components/Icons";
import Ribbon from "components/Labels/Ribbon/Ribbon";
import Text from "components/Text/Text";
import {
  ANNUAL_PLAN_DISCOUNT,
  ANNUAL_PLAN_IDS,
  MOBILE_PLAN_ORDER,
  PLAN_PRICING_TABLE_INFO,
} from "constants/plans";

// hooks:
import usePlanChange from "hooks/usePlanChange";

import DiscountTag from "components/Labels/DiscountTag";

import styles from "./PlanTableCardMobile.module.scss";

export default function PlanTableCardMobile({
  planId,
  activePlan,
  handleClick,
  loading,
  disabled,
}) {
  const {
    getButtonText,
    getButtonVariant,
    useRibbon,
    isDisabled,
    getPricingText,
    PRICING_TYPE,
    FREE_PRODUCT,
  } = usePlanChange();

  // derived variables and values:
  const planDesciption = PLAN_PRICING_TABLE_INFO[planId].plan_description;
  const planType = PLAN_PRICING_TABLE_INFO[planId].plan_type;
  const pricingInfo = getPricingText(planId);
  const featuresList = PLAN_PRICING_TABLE_INFO[planId].features_list;
  const iconIsPresentInPlan = PLAN_PRICING_TABLE_INFO[planId].hasIcon;

  const mobilePlan = MOBILE_PLAN_ORDER.filter(
    (plan) => plan.plan_type === planType,
  );

  const renderPricingText = (pricingInfo) => {
    if (!pricingInfo || pricingInfo.type === PRICING_TYPE?.NONE) {
      return null;
    }

    return (
      <>
        {pricingInfo.type === PRICING_TYPE?.DISCOUNT && (
          <>
            <del className={styles.oldPrice}>{pricingInfo.originalPrice}</del>{" "}
            <span>{pricingInfo.price}</span>
            <p className={styles.simpleText}>{pricingInfo.note}</p>
          </>
        )}
        {pricingInfo.type === PRICING_TYPE?.STANDARD && (
          <>
            {/* {pricingInfo.note} */}
            {pricingInfo.price}
          </>
        )}
      </>
    );
  };

  return (
    <Card
      data-testid="planTableCard"
      className={`${styles.planTableCardMobile} `}
    >
      {useRibbon(planId) && <Ribbon>Best Value</Ribbon>}
      <Stack className={styles.stackWrapper}>
        <Box className={styles.planTypeBox}>
          <Text variant="h1" className={styles.title}>
            {planType}
            {iconIsPresentInPlan && <CrownIcon className={styles.crownIcon} />}
          </Text>
          <Box className={styles.pricingBox}>
            {Object.keys(pricingInfo).length > 0 && (
              <Text variant="h4" className={styles.price}>
                {renderPricingText(pricingInfo)}
              </Text>
            )}
          </Box>
        </Box>

        <Box className={styles.discountTagBox}>
          {ANNUAL_PLAN_IDS.includes(planId) && (
            <DiscountTag
              color="secondary"
              label={ANNUAL_PLAN_DISCOUNT[planId]}
              isFaded
              size="small"
            />
          )}
        </Box>
      </Stack>
      <Box className={styles.ctaPlaceHolder}>
        {planId !== 0 && (
          <Button
            variant={getButtonVariant(planId, activePlan)}
            color="primary"
            loading={loading}
            hasChevron
            disabled={isDisabled(planId, activePlan) || disabled}
            className={styles.cta}
            onClick={() => handleClick(planId)}
          >
            {getButtonText(planId, activePlan)}
          </Button>
        )}
      </Box>
      <Text className={styles.description}>{planDesciption}</Text>
      <Divider className={styles.divider} />

      <CardContent className={styles.cardContent}>
        <Box>
          <ul>
            <Text variant="h3" className={styles.cardContentLeft}>
              {PLAN_PRICING_TABLE_INFO[0].features_list.map(
                (feature, index) => {
                  const key = `${feature.id}`;
                  if (index < mobilePlan[0].number_of_features_to_display) {
                    return <li key={key}>{feature.feature}</li>;
                  }
                  return null;
                },
              )}
            </Text>
          </ul>
        </Box>
        <Box className={styles.cardContentRight}>
          <ul>
            {featuresList.map((feature, index) => {
              if (index > 1 && feature.feature === "Cross") {
                return null;
              }
              const key = `${feature.id}`;
              if (feature.feature === "Cross") {
                return (
                  <li>
                    <CloseIcon
                      key={key}
                      className={styles.featureNotProvided}
                    />
                  </li>
                );
              }
              if (feature.feature === "COMING_SOON") {
                return (
                  <li>
                    <ComingSoonTag label="Coming soon" />
                  </li>
                );
              }
              if (feature.feature === "Check") {
                return (
                  <li>
                    <CheckCircleIcon
                      key={key}
                      className={styles.featureProvided}
                    />
                  </li>
                );
              }
              return (
                <li
                  key={key}
                  className={feature.feature === FREE_PRODUCT && styles.bold}
                >
                  {feature.feature}
                </li>
              );
            })}
          </ul>
        </Box>
      </CardContent>
    </Card>
  );
}

PlanTableCardMobile.propTypes = {
  planId: PropTypes.number,
  activePlan: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

PlanTableCardMobile.defaultProps = {
  planId: 1,
  activePlan: 1,
  loading: false,
  disabled: false,
  handleClick: () => {},
};
