import PropTypes from "prop-types";
import React from "react";

// mui components:
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardContent, Stack } from "@mui/material";

// custom components & constants:
import Button from "components/Buttons/Button";
import { CrownIcon } from "components/Icons";
import ComingSoonTag from "components/Labels/ComingSoonTag";
import Ribbon from "components/Labels/Ribbon/Ribbon";
import Text from "components/Text/Text";
import {
  ANNUAL_PLAN_DISCOUNT,
  ANNUAL_PLAN_IDS,
  PLAN_PRICING_TABLE_INFO,
} from "constants/plans";
// hooks:

import { usePlanChange } from "hooks";

import DiscountTag from "components/Labels/DiscountTag";
import styles from "./PlanTableCard.module.scss";

export default function PlanTableCard({
  planId,
  activePlan,
  handleClick,
  loading,
  switchChecked,
  allowAllSelect,
  disabled,
}) {
  const {
    getButtonText,
    getButtonVariant,
    useRibbon,
    isDisabled,
    getPricingText,
    PRICING_TYPE,
    VIP_PLAN_IDS,
  } = usePlanChange();

  const planDesciption = PLAN_PRICING_TABLE_INFO[planId].plan_description;
  const planType = PLAN_PRICING_TABLE_INFO[planId].plan_type;
  const pricingInfo = getPricingText(planId);
  const featuresList = PLAN_PRICING_TABLE_INFO[planId].features_list;
  const annualPlanDiscount = ANNUAL_PLAN_DISCOUNT;
  const iconIsPresentInPlan = PLAN_PRICING_TABLE_INFO[planId].hasIcon;
  const isAnnual = ANNUAL_PLAN_IDS.includes(planId);

  const renderPricingText = (pricingInfo) => {
    if (!pricingInfo || pricingInfo.type === PRICING_TYPE?.NONE) {
      return null;
    }

    return (
      <>
        {pricingInfo.type === PRICING_TYPE?.DISCOUNT && (
          <>
            <del className={styles.oldPrice}>{pricingInfo.originalPrice}</del>{" "}
            <span>{pricingInfo.price}</span>
            <p className={styles.simpleText}>{pricingInfo.note}</p>
          </>
        )}
        {pricingInfo.type === PRICING_TYPE?.STANDARD && (
          <span>{pricingInfo.price}</span>
        )}
      </>
    );
  };

  return (
    <Card
      data-testid="planTableCard"
      className={`${styles.planTableCard} ${planId === 0 && styles.featureColumn} ${VIP_PLAN_IDS.includes(planId) && styles.cardEmphasis} ${planId === 0 && styles.noHover}`}
    >
      {useRibbon(planId) && <Ribbon>Best Value</Ribbon>}
      <Stack className={styles.stackWrapper}>
        <Box className={styles.discountTagBox}>
          {isAnnual && (
            <DiscountTag
              color="secondary"
              label={annualPlanDiscount[planId]}
              isFaded
              size="small"
            />
          )}
        </Box>

        <Text
          variant="h1"
          className={planType === null ? styles.tableTitle : styles.title}
        >
          {planType || "Pick Your Plan"}
          {iconIsPresentInPlan && <CrownIcon className={styles.crownIcon} />}
          <Box className={styles.boxContainer}>
            {planId === 0 && switchChecked && (
              <DiscountTag
                color="secondary"
                label="Save up to 50% with an annual plan!"
                isFaded
                size="small"
              />
            )}
          </Box>
        </Text>

        <Text className={styles.description}>{planDesciption}</Text>

        <Box className={styles.ctaPlaceHolder}>
          {planId !== 0 && (
            <Button
              variant={
                allowAllSelect
                  ? "contained"
                  : getButtonVariant(planId, activePlan)
              }
              color="primary"
              loading={loading}
              hasChevron
              disabled={
                (isDisabled(planId, activePlan) && !allowAllSelect) || disabled
              }
              className={styles.cta}
              onClick={() => handleClick(planId)}
            >
              {allowAllSelect
                ? "Get Started"
                : getButtonText(planId, activePlan)}
            </Button>
          )}
        </Box>

        <Box className={styles.pricingBox}>
          {Object.keys(pricingInfo).length > 0 && (
            <Text variant="h4" className={styles.price}>
              {renderPricingText(pricingInfo)}
            </Text>
          )}
        </Box>
      </Stack>

      <CardContent
        className={`${styles.cardContent} ${planId === 0 && styles.noPlanFeatureColumn}`}
      >
        {featuresList.map((feature, index) => {
          const key = `${feature.id}`;
          if (feature.feature === "Cross") {
            return (
              <li key={key}>
                <CloseIcon key={key} className={styles.featureNotProvided} />
              </li>
            );
          }
          if (feature.feature === "COMING_SOON") {
            return (
              <li key={key}>
                <ComingSoonTag label="Coming soon" />
              </li>
            );
          }
          if (feature.feature === "Check") {
            return (
              <li key={key}>
                <CheckCircleIcon key={key} className={styles.featureProvided} />
              </li>
            );
          }
          if (feature.feature === "Upon request") {
            return (
              <li key={key} className={styles.text}>
                {feature.feature}
              </li>
            );
          }
          return (
            <li key={key} className={`${planId === 0 && styles.text} `}>
              {PLAN_PRICING_TABLE_INFO[0].features_list[index].hasIcon && (
                <CrownIcon className={styles.crownIcon} />
              )}
              {feature.feature}
            </li>
          );
        })}
      </CardContent>
    </Card>
  );
}

PlanTableCard.propTypes = {
  planId: PropTypes.number,
  activePlan: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  allowAllSelect: PropTypes.bool,
};

PlanTableCard.defaultProps = {
  planId: 1,
  activePlan: 1,
  loading: false,
  disabled: false,
  handleClick: () => {},
  allowAllSelect: false,
};
