import {
  Add,
  ArrowBack,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  ShoppingBasketOutlined,
} from "@mui/icons-material";
import Text from "components/Text";
import queryClient from "api/queryClient";
import StarIcon from "@mui/icons-material/Star";
import { QUERY } from "api";
import { getProductV2 } from "api/endpoints/productsAPi";
import Button from "components/Buttons/Button";
import { CrownIcon } from "components/Icons";
import ImageCarousel from "components/ImageCarousel";
import ExpiringTag from "components/Labels/ExpiringTag";
import LowStockTag from "components/Labels/LowStockTag";
import OutOfStockTag from "components/Labels/OutOfStockTag";
import ProductTag from "components/Labels/ProductTag";
import PageHeader from "components/PageHeader";
import Popper from "components/Popper";
import useChangePlanModal from "hooks/useChangePlanModal";
import { useAnalytics, useQueryParam, useProfile } from "hooks";
import { SMALL } from "constants/breakpoints";
import {
  PRODUCT_TYPE_UNBRANDED,
  PRODUCT_TYPE_CUSTOM,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
} from "constants/constants";
import { FILTER_PREMIUM } from "constants/filters";
import parse from "html-react-parser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import darkenColor from "views/FindProductsView/components/ProductCard/darkenColor";
import ProductViewSkeleton from "./components/ProductViewSkeleton/ProductViewSkeleton";
import styles from "./ProductView.module.scss";
import useProductView from "./useProductView";

const icons = [
  {
    src: "/icon/product-view/cruelty-free.svg",
    label: "Cruelty Free",
    value: "cruelty-free",
  },
  { src: "/icon/product-view/organic.svg", label: "Organic", value: "organic" },
  {
    src: "/icon/product-view/pareben-free.svg",
    label: "Paraben Free",
    value: "paraben-free",
  },
  { src: "/icon/product-view/vegan.svg", label: "Vegan", value: "vegan" },
];

function ProductView() {
  const analytics = useAnalytics();
  const params = useParams();
  const query = useQueryParam();
  const { shop } = useProfile();
  const profileHook = useProfile();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantsCollapsed, setVariantsCollapsed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onOpenModal } = useChangePlanModal();

  const hasCustomProduct =
    shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE;

  const productType = useMemo(() => {
    const type = query.get("product_type");
    if (type) {
      if (type === PRODUCT_TYPE_CUSTOM && !hasCustomProduct) {
        return PRODUCT_TYPE_UNBRANDED;
      }
      return type;
    }
    return hasCustomProduct ? PRODUCT_TYPE_CUSTOM : PRODUCT_TYPE_UNBRANDED;
  }, [query, hasCustomProduct]);

  useEffect(() => {
    const type = query.get("product_type");
    if (type === PRODUCT_TYPE_CUSTOM && !hasCustomProduct) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("product_type", PRODUCT_TYPE_UNBRANDED);
      setSearchParams(newSearchParams);
    }
  }, [productType, hasCustomProduct, searchParams, setSearchParams]);

  const { data, isFetched } = useQuery(
    [QUERY.getProduct, params.sku],
    () => getProductV2(params.sku, productType),
    {
      enabled: !!params?.sku,
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    if (selectedVariant) {
      const payload = {
        sku: selectedVariant.sku,
        product_type: selectedVariant?.product_type,
        product_group: data?.data?.product_group[0]?.name, // items only belong to 1 product group for now
      };
      analytics.sendEvent(analytics.PRODUCT_PAGE_VIEW, payload);
    }
  }, [selectedVariant]);

  const variants = useMemo(() => data?.data?.product_group || [], [data]);

  const onChangeVariant = (variant) => {
    setSelectedVariant(variant);
    setSearchParams({
      variant: variant?.sku,
      product_type: productType,
    });
  };

  const handleGoBack = () => {
    // if the search term is present, navigate to search page
    if (searchParams.get("search_term")) {
      navigate(`/search?search_term=${searchParams.get("search_term")}`);
      return;
    }

    // Navigate back to the stored URL
    const prevUrl = sessionStorage.getItem("prevUrl");
    if (prevUrl) {
      navigate(prevUrl);

      // Retrieve the saved scroll position
      const prevScrollPosition = sessionStorage.getItem("prevScrollPosition");

      // Scroll to the previous position after navigation
      setTimeout(() => {
        window.scrollTo(0, parseInt(prevScrollPosition || 0, 10));
      }, 0);

      // Clean up after navigation
      sessionStorage.removeItem("prevUrl");
      sessionStorage.removeItem("prevScrollPosition");
    }
  };

  const { loading, handleAddToStore, handleOrderSamples, setProduct } =
    useProductView();

  const handleLocalAddToStore = () => {
    handleAddToStore(selectedVariant);
    // reload products
    queryClient.refetchQueries([QUERY.getProducts]);
    queryClient.refetchQueries([QUERY.getUser]);
  };

  useEffect(() => {
    setProduct(selectedVariant);
  }, [selectedVariant]);

  useEffect(() => {
    if (variants) {
      const variantSku = query.get("variant");
      if (variantSku) {
        const index = variants.findIndex(
          (variant) => variant.sku === variantSku
        );
        if (index > 8) {
          setVariantsCollapsed(true);
        }
        setSelectedVariant(variants[index]);
        setProduct(variants[index]);
      } else {
        setSelectedVariant(variants[0]);
        setProduct(variants[0]);
      }
    }
  }, [variants, query]);

  const productImages = useMemo(
    () => selectedVariant?.product_images || [],
    [selectedVariant]
  );

  const renderCategories = () => {
    if (selectedVariant?.categories) {
      const matchingIcons = icons.filter((icon) =>
        selectedVariant?.categories?.includes(icon.value)
      );

      if (matchingIcons) {
        return matchingIcons.map((icon) => (
          <Popper text={icon.label} key={icon.label}>
            <img
              className={styles.productBenefitsIcon}
              src={icon.src}
              alt={icon.label}
            />
          </Popper>
        ));
      }
    }

    return null;
  };

  const goToNextVariant = () => {
    const currentIndex = variants.findIndex(
      (variant) => variant?.sku === selectedVariant?.sku
    );
    if (currentIndex === variants.length - 1) {
      onChangeVariant(variants[0]);
    } else {
      onChangeVariant(variants[currentIndex + 1]);
    }
  };

  const gotoPreviousVariant = () => {
    const currentIndex = variants.findIndex(
      (variant) => variant.sku === selectedVariant?.sku
    );
    if (currentIndex === 0) {
      onChangeVariant(variants[variants.length - 1]);
    } else {
      onChangeVariant(variants[currentIndex - 1]);
    }
  };

  const renderVariants = () =>
    variants.map((variant) => (
      <button
        type="button"
        onClick={() => onChangeVariant(variant)}
        key={variant?.sku}
        className={`${styles.variantBlock} ${selectedVariant?.sku === variant.sku && styles.variantBlockActive}`}
      >
        {variant?.color_code ? (
          <div
            style={{
              border: `1px solid ${darkenColor(variant?.color_code, 12)}`,
              background: variant?.color_code,
            }}
            className={styles.variantCircle}
          >
            {variant?.added_to_my_products && (
              <div className={styles.variantSelected}>
                <img
                  style={{ height: 10, width: 10 }}
                  src="/icon/product-view/product-check.svg"
                  alt="check"
                />
              </div>
            )}
          </div>
        ) : (
          <img
            className={styles.variantCircle}
            src="/icon/product-view/no-color.svg"
            alt="no color"
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 24,
            justifyContent: "center",
          }}
        >
          <span
            style={
              selectedVariant?.sku === variant?.sku ? { color: "#D56F5B" } : {}
            }
            className={styles.variantName}
          >
            {variant?.color_name}
          </span>
          <span
            className={`${styles.skuLabel} ${selectedVariant?.sku === variant?.sku && styles.skuLabelActive}`}
            style={{
              fontSize: "8px",
              letterSpacing: "2px",
              lineHeight: "10px",
              fontWeight: 600,
              textTransform: "uppercase",
              color: "#5E5E5E",
            }}
          >
            {variant?.sku}
          </span>
        </div>
      </button>
    ));

  const productDescription = useMemo(() => {
    if (selectedVariant?.description) {
      return parse(selectedVariant?.description);
    }

    return null;
  }, [selectedVariant]);

  const productNotes = useMemo(() => {
    if (selectedVariant?.product_notes) {
      return parse(selectedVariant?.product_notes);
    }

    return null;
  }, [selectedVariant]);

  const productBenefits = useMemo(() => {
    if (selectedVariant?.benefits) {
      return parse(selectedVariant?.benefits);
    }

    return null;
  }, [selectedVariant]);

  const productApplication = useMemo(() => {
    if (selectedVariant?.application) {
      return parse(selectedVariant?.application);
    }

    return null;
  }, [selectedVariant]);

  const productIngredients = useMemo(() => {
    if (selectedVariant?.ingredients) {
      return parse(selectedVariant?.ingredients);
    }

    return null;
  }, [selectedVariant]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current.scrollHeight > 34);
    }
  }, [productDescription]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const ifThisGetsPassedRef = useRef(null); // Ref to track the element
  const [showActionsFixed, setShowActionsFixed] = useState(false); // Control fixed button visibility

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const { scrollY } = window;
      const current = ifThisGetsPassedRef.current?.getBoundingClientRect().top;
      const elementPosition = current + scrollY; // Get element's position relative to page

      // Show only if scrolled past the element AND scrolled more than 100vh
      setShowActionsFixed(scrollY > elementPosition && scrollY > windowHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderBulkContent = () => {
    if (selectedVariant?.vouchers?.length > 0) {
      return selectedVariant?.vouchers?.map((voucher) => (
        <div className={styles.productBulkBlock}>
          <span className={styles.quantity}>
            {voucher.min} {voucher.max ? `- ${voucher.max}` : "+"}
          </span>
          <span className={styles.units}>UNITS</span>
          <span className={styles.off}>{voucher.label}</span>
        </div>
      ));
    }

    return null;
  };

  const isPremium = useMemo(
    () => selectedVariant?.categories.includes(FILTER_PREMIUM),
    [selectedVariant]
  );

  if (!isFetched) {
    return (
      <div>
        <PageHeader
          title="Product Details"
          leftAction={
            <button
              className={styles.backButton}
              type="button"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </button>
          }
        />
        <ProductViewSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.productView}>
      <PageHeader
        title="Product Details"
        className={styles.pageHeader}
        leftAction={
          <button
            className={styles.backButton}
            type="button"
            onClick={handleGoBack}
          >
            <ArrowBack />
          </button>
        }
      />
      <span onClick={handleGoBack} className={styles.backLink}>
        BACK
      </span>
      <div className={styles.productViewContainer}>
        <div className={styles.productTypes}>
          {selectedVariant?.product_type === "BLANK" && (
            <ProductTag productType={selectedVariant?.product_type} />
          )}
          {isPremium && (
            <div className={styles.premiumTag}>
              <StarIcon style={{ height: 10, width: 10 }} />
              Premium
            </div>
          )}
        </div>
        <div className={styles.productViewGrid}>
          <div className={styles.productImages}>
            <div className={styles.productStatusContainer}>
              {selectedVariant?.product_base?.expires_at !== null && (
                <ExpiringTag
                  expiryDate={selectedVariant?.product_base?.expires_at}
                  isFullWidth={isMobile}
                />
              )}
              {selectedVariant?.product_base?.out_of_stock && (
                <OutOfStockTag
                  expiryDate={selectedVariant?.product_base?.out_of_stock_date}
                  isFullWidth={isMobile}
                />
              )}
              {selectedVariant?.product_base?.low_stock && (
                <LowStockTag isFullWidth={isMobile} />
              )}
            </div>
            {productImages.length > 0 ? (
              <ImageCarousel
                images={[{ image: selectedVariant?.image }, ...productImages]}
                className={styles.productImgs}
              />
            ) : (
              <img src={selectedVariant?.image} alt="Product" />
            )}

            {selectedVariant?.product_base?.vip_only && (
              <div className={styles.vipContainer}>
                <CrownIcon isPartialLength />
              </div>
            )}
          </div>
          <div className={styles.productMain}>
            <div className={styles.productMainHeader}>
              <h1 className={styles.productName}>{selectedVariant?.name}</h1>
              <span className={styles.productSku}>
                {selectedVariant?.color_name
                  ? `${selectedVariant.color_name} | `
                  : ""}
                SKU: {selectedVariant?.sku}
              </span>
              <div className={styles.productDescription}>
                <div
                  ref={descriptionRef}
                  style={{
                    maxHeight: isExpanded ? "none" : "34px",
                    overflow: "hidden",
                  }}
                >
                  {productDescription}

                  <p className={styles.productNotesText}>{productNotes}</p>
                </div>
                {isOverflowing && (
                  <span className={styles.readMore} onClick={toggleExpand}>
                    {isExpanded ? "Show less" : "Read more"}
                  </span>
                )}
              </div>
              <div className={styles.productBenefits}>{renderCategories()}</div>

              <div className={styles.productFooter}>
                <div className={styles.productFooterPrice}>
                  <div style={{ height: 14 }}>
                    <span className={styles.dollarSign}>$</span>
                    <span className={styles.price}>
                      {selectedVariant?.cost}
                    </span>
                  </div>
                  <div>
                    <span className={styles.priceLabel}>unit cost</span>
                  </div>
                </div>

                <div className={styles.productFooterActions}>
                  <Popper text="Suggested MSRP">
                    <div className={styles.productSrp}>
                      <div>
                        <span
                          style={{ color: "#246071" }}
                          className={styles.dollarSign}
                        >
                          $
                        </span>
                        <span
                          style={{ color: "#246071" }}
                          className={styles.price}
                        >
                          {selectedVariant?.suggested_cost}
                        </span>
                      </div>
                      <span
                        style={{ color: "#246071" }}
                        className={styles.priceLabel}
                      >
                        SRP
                      </span>
                    </div>
                  </Popper>

                  <div className={styles.productProfit}>
                    <div>
                      <span
                        style={{ color: "#FFF" }}
                        className={styles.dollarSign}
                      >
                        $
                      </span>
                      <span style={{ color: "#FFF" }} className={styles.price}>
                        {parseFloat(
                          Number(selectedVariant?.suggested_cost) -
                            Number(selectedVariant?.cost)
                        ).toFixed(2)}
                      </span>
                    </div>
                    <span
                      style={{ color: "#FFF" }}
                      className={styles.priceLabel}
                    >
                      PROFIT
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.divider} />

              <div>
                {variants.length > 1 && (
                  <div className={styles.productVariants}>
                    <div className={styles.productVariantNav}>
                      <span className={styles.productVariantNavTitle}>
                        COLORS ({variants.length})
                      </span>
                      <button
                        onClick={gotoPreviousVariant}
                        type="button"
                        className={styles.variantArrow}
                      >
                        <ChevronLeft style={{ color: "#979797" }} />
                      </button>
                      <button
                        onClick={goToNextVariant}
                        type="button"
                        className={styles.variantArrow}
                      >
                        <ChevronRight style={{ color: "#979797" }} />
                      </button>
                    </div>

                    <div
                      className={`${styles.productVariantValues} ${variantsCollapsed && styles.productVariantValuesExpanded}`}
                      style={{ maxHeight: "150px" }}
                    >
                      {renderVariants()}
                    </div>
                    {variants?.length > 9 && (
                      <div
                        className={styles.productVariantCollapseControl}
                        onClick={() => setVariantsCollapsed((prev) => !prev)}
                      >
                        <div
                          className={`${styles.productVariantCollapseControlShadow} ${!variantsCollapsed ? styles.visible : ""}`}
                        />
                        {!variantsCollapsed ? (
                          <span>
                            <ExpandMore style={{ color: "#5E5E5E" }} /> See more
                          </span>
                        ) : (
                          <span>
                            <ExpandLess style={{ color: "#5E5E5E" }} /> See less
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.productActions}>
                  <Button
                    startIcon={<ShoppingBasketOutlined />}
                    color="primary"
                    size={isMobile ? "medium" : "small"}
                    onClick={() => handleOrderSamples()}
                    loading={loading === "sample"}
                    className={styles.orderButton}
                    disabled={
                      selectedVariant?.product_base?.is_discontinued ||
                      selectedVariant?.product_base?.out_of_stock
                    }
                  >
                    Order samples
                  </Button>

                  {selectedVariant?.added_to_my_products ? (
                    <div className={styles.addedToMyProducts}>
                      <img
                        style={{ height: 13, width: 13 }}
                        src="/icon/product-view/product-check.svg"
                        alt="check"
                      />
                      <span>Added to my products</span>
                    </div>
                  ) : null}

                  {!selectedVariant?.added_to_my_products &&
                    !profileHook.isVIPPlan() &&
                    selectedVariant?.product_base?.vip_only && (
                      <>
                        <Text className={styles.vipWarning}>
                          This product is a part of our Early Access collection{" "}
                          <strong>only available to VIP users</strong>. To add
                          this product to your store, upgrade now!
                        </Text>
                        <div className={styles.upgradeText}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={styles.earlyAccessButton}
                            size={isMobile ? "small" : "medium"}
                            onClick={() => onOpenModal()}
                          >
                            Unlock Access
                          </Button>
                        </div>
                      </>
                    )}

                  {!selectedVariant?.added_to_my_products &&
                    (profileHook.isVIPPlan() ||
                      !selectedVariant?.product_base?.vip_only) && (
                      <Button
                        startIcon={!isMobile && <Add />}
                        color="secondary"
                        variant="outlined"
                        size={isMobile ? "medium" : "small"}
                        onClick={() => handleAddToStore()}
                        loading={loading === "store"}
                        disabled={
                          selectedVariant?.product_base?.is_discontinued
                        }
                      >
                        Add to my products
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.divider} />

        <div className={styles.productInformationContainer}>
          <div className={styles.productInformationContainerRow}>
            <div>
              <span className={styles.productInformationTitle}>Benefits</span>
            </div>
            <div className={styles.productInformationBody}>
              {productBenefits}
              <div
                className={styles.productBenefits}
                style={{ marginTop: "1rem" }}
              >
                {renderCategories()}
              </div>
            </div>
          </div>

          <div className={styles.productInformationContainerRow}>
            <div>
              <span className={styles.productInformationTitle}>
                Application
              </span>
            </div>
            <div className={styles.productInformationBody}>
              {productApplication}
            </div>
          </div>

          <div className={styles.productInformationContainerRow}>
            <div>
              <span className={styles.productInformationTitle}>
                Ingredients
              </span>
            </div>
            <div className={styles.productInformationBody}>
              {productIngredients}
            </div>
          </div>

          {selectedVariant?.vouchers?.length > 0 && (
            <div className={styles.productInformationContainerRow}>
              <div>
                <span className={styles.productInformationTitle}>
                  Bulk Inventory Orders
                </span>
                <div style={{ marginTop: 8 }} className={styles.infoTag}>
                  <span>Save up to 20% off</span>
                </div>
              </div>
              <div className={styles.productInformationBody}>
                <p>
                  Take advantage of our exclusive volume discounts when you
                  stock up your inventory! Volume discounts may not be
                  compatible with some cart discounts. See details at:{" "}
                  <a
                    href="https://faq.blankabrand.com/en/articles/8533867-volume-discount-schedule"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pricing FAQ.
                  </a>
                </p>
                <div className={styles.productBulkInventoryOrders}>
                  <div>
                    <span className={styles.saveMore}>
                      Save more when you order more!
                    </span>
                  </div>
                  <div className={styles.productBulkBlocksContainer}>
                    {renderBulkContent()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.divider} />
      </div>

      {isMobile && (
        <div
          id="showThisWithTransition"
          className={`${styles.productActionsFixed} ${
            showActionsFixed ? styles.visible : ""
          }`}
        >
          <Button
            startIcon={<ShoppingBasketOutlined />}
            color="primary"
            size={isMobile ? "medium" : "small"}
            onClick={() => handleOrderSamples(selectedVariant)}
            loading={loading === "sample"}
          >
            Order samples
          </Button>
          <Button
            startIcon={!isMobile && <Add />}
            color="secondary"
            variant="outlined"
            size={isMobile ? "medium" : "small"}
            onClick={() => handleAddToStore(selectedVariant)}
            loading={loading === "store"}
          >
            Add to my products
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProductView;
